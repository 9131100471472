module.exports = {
  "首页": "첫 페이지",
  "订单": "주문",
  "个人": "개인",
  "DNA": "DNA",
  "权益专区": "권익 전구",
  "精选好物": "좋은 물건을 정선하다.",
  "健康服务": "건강 서비스",
  "特惠专区": "특별 행사 구역",
  "公告": "공지",
  "精选商品": "정선 상품",
  "立即购买": "지금 구매",
  "没有更多了": "더 이상 없어요.",
  "待付款": "지불 대기",
  "待确认": "확인 대기 중",
  "待核销": "폐기 대기 중",
  "已完成": "완료됨",
  "订单取消": "주문 취소",
  "地址验证登录": "주소 확인 로그인",
  "收益钱包": "수익 지갑",
  "LP代币": "LP 토큰",
  "我的订单": "내 주문",
  "我的社区": "내 커뮤니티",
  "分享好友": "친구 공유",
  "分享": "친구",
  "语言切换": "언어 전환",
  "客服中心": "고객센터",
  "密码设置": "암호 설정",
  "商品详情": "상품 상세 정보",
  "赠送": "증정",
  "数量": "수량",
  "限时特惠": "특별 행사",
  "产品详情": "제품 상세 정보",
  "立即购买": "지금 구매",
  "请先设置安全密碼": "먼저 거래 비밀번호를 설정하십시오.",
  "输入支付密码": "결제 비밀번호 입력",
  "价值": "가치",
  "提交": "제출",
  "立即支付": "즉시 지불",
  "成功": "성공",
  "失败": "실패",
  "消息列表": "메시지 목록",
  "订单详情": "주문 상세 정보",
  "价格明细": "가격 명세",
  "商品总价": "상품 총가격",
  "实际支付": "실제 지불",
  "订单信息": "주문 정보",
  "订单号": "주문 번호",
  "创建时间": "생성 시간",
  "付款时间": "결제 시간",
  "复制成功": "복제 성공",
  "复制失败": "복제 실패",
  "支付成功": "결제 성공",
  "您已完成支付": "결제 완료",
  "知道了": "알았어",
  "安全密碼": "거래 암호",
  "旧安全密碼": "이전 거래 암호",
  "请输入旧安全密碼": "이전 거래 암호를 입력하십시오.",
  "重复密码": "암호 반복",
  "请输入重复安全密碼": "반복 거래 암호를 입력하십시오.",
  "确定": "확인",
  "两次安全密碼输入不一致": "두 번의 거래 비밀번호 입력이 일치하지 않음",
  "请确认安全密碼": "거래 비밀번호 확인",
  "客服留言": "고객 서비스 메시지",
  "记录": "기록",
  "请输入留言": "메시지를 입력하십시오.",
  "发送": "발송",
  "反馈记录": "피드백 레코드",
  "查看回复": "응답 보기",
  "反馈详情": "피드백 상세 정보",
  "回复": "회신",
  "系统回复": "시스템 응답",
  "分享链接": "공유 링크",
  "复制分享链接": "공유 링크 복사",
  "复制成功": "복제 성공",
  "复制失败": "복제 실패",
  "明细": "명세",
  "社区业绩": "업적",
  "社区人数": "커뮤니티",
  "直推人数": "직추",
  "社区会员列表": "커뮤니티 회원 목록",
  "密码设置": "암호 설정",
  "修改安全密碼": "거래 암호 수정",
  "钱包明细": "지갑 명세",
  "余额": "잔액",
  "提现": "현금 인출",
  "余额变动明细": "잔액 변동 내역",
  "提现数量": "현금 인출 수량",
  "请输入提现数量": "현금 인출 수량을 입력하십시오.",
  "手续费": "수수료",
  "最低提现数量": "시작 수",
  "提现倍数": "현금 인출 배수",
  "实际到账": "실제 입금",
  "请输入安全密碼": "거래 암호를 입력하십시오.",
  "提现记录": "현금 인출 기록",
  "待审核": "보류 중",
  "已通过": "통과",
  "已驳回": "기각됨",
  "钱包余额不足": "지갑 잔액 부족",
  "取消订单": "주문 취소",
  "系统未设置收款钱包地址": "시스템이 수금 지갑 주소를 설정하지 않았습니다.",
  "请地址验证登录": "주소 확인 로그인",
  "钱包等待投放，暂时无法使用": "지갑 대기 중, 잠시 사용 불가",
  "请切换到": "이더리움 Ethereum 마스터 네트워크, Binance Smartchain으로 전환하십시오.",
  "空投": "공중 투하 시간",
  "赠送": "증정 시간",
  "请复制您的链接在DAPP浏览器访问": "DAPP 브라우저에서 링크를 복사하여 액세스하십시오.",
  "暂未绑定上级": "상급자를 아직 귀속시키지 않았습니다. 먼저 귀속해 주십시오",
  "请输入上级": "상급자를 입력하십시오. UID",
  "请输入": "입력하십시오.",
  "有效": "유효",
  "无效": "유효하지 않음",
}