<template>
  <div class='index'>
    <nav-bar title="商品详情" left-arrow />
    <van-swipe class="myswipe" :autoplay="3000" indicator-color="#fff">
      <van-swipe-item v-for="val in slider" :key="val.id">
        <img :src="val" alt>
      </van-swipe-item>
    </van-swipe>
    <div class="minbox">
      <div class="small_box">
        <div class="left">
          <p class="money">{{ info.usdt_price }}<span>U</span></p>
          <p class="msg"> {{ info.service }}</p>
          <!-- {{ info.service }}{{ $t('数量') }} -->
        </div>
        <p class="right">
          <span>{{ $t('限时特惠') }}</span>
        </p>
      </div>
      <p class="name">{{ info.name }}</p>
    </div>
    <div class="tipbox">
      <span></span>
      <p>{{ $t('产品详情') }}</p>
      <span></span>
    </div>
    <div class="shangpin htmlcententbox" v-html="info.content"></div>
    <van-goods-action v-if="paydata.payUsdt">
      <van-goods-action-button type="danger" color="linear-gradient(90deg, #FFE4CC 0%, #F9CEB0 48%, #F2B692 100%)"
        :text="$t('立即购买')" @click="show = true" />
    </van-goods-action>

    <van-popup v-model="show" closeable round style="width:90%">
      <div class="content">
        <!-- <p class="tit">{{ $t('输入支付密码') }}</p> -->
        <p class="paynum">{{ paydata.payUsdt }}USDT</p>
        <!-- <van-field maxlength="6" v-model="password" :placeholder="$t('请输入安全密碼')" clearable type="password" /> -->
        <van-button type="primary" color='linear-gradient(90deg, #FFE4CC 0%, #F8CCAE 52%, #F7C8A9 60%, #F2B692 100%)'
          block :loading="loading" @click="createOrder">{{ $t('立即支付') }}</van-button>
      </div>
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: this.$route.query.id,
      slider: [],
      info: {},
      paydata: {},
      address_info: {},
      show: false,
      password: '',
      loading: false,
    }
  },
  computed: {
    is_chain() {
      return this.$store.state.wallet.chainId
    }
  },
  created() {
    window.scrollTo(0, 0)
    console.warn('chainId', this.is_chain);
    this.getdetails()
  },
  methods: {
    getdetails() {
      this.$post({
        loading: true,
        url: '/app/goods/info',
        data: {
          id: this.id,
        },
        success: (res) => {
          this.slider = res.data.slider
          this.info = res.data
          this.getpaydata()
        },
        tip: () => { },
      })
    },
    getpaydata() {
      this.$post({
        url: '/app/member/getInfo',
        success: (res) => {
          this.address_info = res.data
        },
        tip: () => { },
      })
      this.$post({
        url: '/app/orders/createOrderInfo',
        data: {
          goods_id: this.id,
        },
        success: (res) => {
          this.paydata = res.data
        },
        tip: () => { },
      })
    },
    order() {
      this.buy_show = false
    },
    createOrder() {
      // if (!this.password) return this.$toast(this.$t('请输入安全密碼'))
      let inAddress = ''
      let precision = ''
      if (this.is_chain == 1) {
        inAddress = this.address_info.etc_recharge
        precision = 'mwei'
      } else if (this.is_chain == 56) {
        inAddress = this.address_info.bsc_recharge
        precision = 'ether'
      }
      if (inAddress) {
        this.loading = true
        this.$post({
          url: '/app/orders/createOrder',
          data: {
            goods_id: this.id,
            // password: this.password,
          },
          success: (res) => {
            this.transfer(res.data, inAddress, precision);
          },
          tip: () => {
            this.loading = false
          },
        })
      } else {
        this.loading = false
        this.$toast({
          message: this.$t("系统未设置收款钱包地址")
        })
      }
    },
    transfer(order_id, inAddress, precision) {
      let that = this;
      that.$nextTick(() => {
        that.blockFun
          .transfer("usdt", String(that.paydata.payUsdt), inAddress, precision, false, that.is_chain)
          .then((hash) => {
            that.paySuccess(order_id, hash);
          })
          .catch((err) => {
            console.log(err);
            that.loading = false;
          });
      });

    },
    paySuccess(order_id, hash) {
      this.$post({
        url: '/app/orders/paySuccess',
        data: {
          order_id,
          hash,
        },
        success: () => {
          this.$toast.success({
            message: this.$t("成功"),
            onClose: () => {
              this.loading = false;
              this.$router.replace('paysuccess')
            },
          });
        },
        tip: () => {
          this.loading = false;
        },
      })
    }
  },
}
</script>
<style scoped lang='less'>
.index {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 0 0 70px;
  .myswipe {
    width: 100%;
    /deep/.van-swipe-item {
      width: 100%;
      overflow: hidden;
      >img {
        width: 100%;
        height: 374px;
        object-fit: contain;
      }
    }
  }
  .minbox {
    width: 100%;
    margin: 0 0 10px;
    .small_box {
      width: 100%;
      background: linear-gradient(280deg, #3A353C 0%, #262231 100%);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 0 10px;
      flex-wrap: wrap;
      .left {
        min-width: 62%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        .money {
          color: #F6BE9D;
          font-size: 25px;
          font-weight: 550;
          span {
            font-size: 15px;
          }
        }
        .msg {
          margin-left: 10px;
          background: #fff;
          border-radius: 30px;
          color: #4D2914;
          padding: 0 4px;
          font-weight: 550;
          font-size: 12px;
        }
      }
      .right {
        background: url('../../../assets/img/n.png') no-repeat;
        background-size: 100% 100%;
        width: 133px;
        height: 62px;
        text-align: center;
        line-height: 62px;
        padding-left: 10px;
        span {
          color: #333333;
          font-size: 21px;
          font-weight: 550;
        }
      }
    }
    .name {
      width: 100%;
      color: #F6BE9D;
      font-size: 16px;
      font-weight: 550;
      line-height: 1.5;
      padding: 10px 15px;
    }
  }
  .tipbox {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      width: 15px;
      height: 1px;
      background: #707070;
    }
    p {
      padding: 0 20px;
      color: #F6BE9D;
      font-size: 14px;
      font-weight: 550;
    }
  }
  .shangpin {
    width: 100%;
    padding: 10px 15px 70px;
  }
  /deep/.van-popup {
    background: #000000;
  }
  .content {
    padding: 20px;
    .tit {
      width: 100%;
      text-align: center;
      color: #FFFFFF;
      font-size: 16px;
      font-weight: 550;
    }
    .paynum {
      width: 100%;
      color: #F6BE9D;
      font-size: 14px;
      font-weight: 550;
      padding: 10px 0 20px;
      text-align: center;
    }
    /deep/.van-field {
      background: #000000;
      margin: 40px 0 50px;
      border: 1px solid #FCDABF;
      border-radius: 4px;
    }
  }
  /deep/.van-goods-action {
    background: transparent;
  }
}
</style>
