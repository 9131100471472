<template>
  <div class='indexbox'>
    <nav-bar title="客服留言" left-arrow />
    <div class="content_box" ref="record" @scroll="handleScroll">
      <div class="listcontent" ref="content">
        <div class="item_loading" v-if="!isCompleted && loading">
          <van-loading />
        </div>
        <div class="chat-notice" v-if="isCompleted">{{ $t('没有更多了') }}</div>
        <div v-for="item in chatMessages" :key="item._id" class="item">
          <div class="userbox" :class="item.type == 0 ? 'author_my' : ''">
            <img src="../../../assets/img/logo.png" alt="" class="user_img">
            <div class="contentbox">
              <p v-if="item.content" class="msg_content">{{ item.content }}</p>
              <div class="img_content">
                <img v-lazy="val" alt="" v-for="(val, index) in  item.images" :key="val"
                  @click="watch_img(item.images, index)" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-goods-action>
      <div class="imgbox">
        <img :src="val.content" alt="" v-for="(val, index) in fileList" :key="index">
      </div>
      <div class="footboxitem">
        <van-uploader v-model="fileList" :max-count="4" :preview-image="false" preview-size="30"
          :after-read="afterRead" />
        <van-field v-model="message" maxlength="120" :placeholder="$t('请输入留言')" :border=false />
        <van-button round color="linear-gradient(90deg, #FFE4CC 0%, #F9CEB0 48%, #F2B692 100%)" :text="$t('发送')"
          :loading="bloading" @click="submit" />
      </div>
    </van-goods-action>
  </div>
</template>
<script>
export default {
  data() {
    return {
      message: '',
      fileList: [],
      bloading: false,

      isCompleted: false,
      loading: false,
      page: 1,
      chatMessages: [],
    }
  },
  created() {
    this.getchatlist()
  },
  methods: {
    //聊天记录
    getchatlist() {
      this.loading = true
      this.$post({
        url: "/app/feedback/pageList",
        data: {
          page: this.page,
          pageSize: 20,
        },
        success: (res) => {
          if (!res.data || res.data.data.length == 0) {
            this.isCompleted = true
          } else {
            let list = res.data.data.reverse()
            if (this.page == 1) {
              this.chatMessages = list
            } else {
              this.chatMessages = [...list, ...this.chatMessages]
            }
            this.$nextTick(() => {
              // this.$refs.record.scrollTop = this.$refs.record.scrollHeight 
              this.$refs.record.scrollTop = this.$refs.content.scrollHeight
            });
          }
          this.loading = false
        },
        tip: () => {
          this.isCompleted = true
        },
      });
    },
    watch_img(data, index) {
      let imgs = []
      data.forEach(item => {
        imgs.push(`${item}`)
      })
      this.lookImg(imgs, index)
    },
    handleScroll(e) {
      if (this.isCompleted || this.loading) return;
      if (e.srcElement.scrollTop == 0) {
        if (!this.isCompleted) {
          this.page++
          this.loading = true
          this.getchatlist()
        }
      }
    },
    afterRead(file) {
      file.status = 'uploading';
      let formData = new FormData()
      formData.append('file', file.file)
      this.$post({
        url: "/app/member/uploadImg",
        data: formData,
        upload: true,
        success: (res) => {
          file.url = res.data.path
          file.status = 'successed'
        },
        tip: () => {
          file.status = 'failed';
          this.loading = false
        }
      })
    },
    submit() {
      if (!this.message) return this.toast(this.$t('请输入留言'))
      let images = []
      this.fileList.forEach(item => {
        if (item.url) {
          images.push(item.url)
        }
      })
      this.bloading = true
      this.$post({
        url: "/app/feedback/add",
        data: {
          content: this.message,
          images: images.length ? images.join(',') : ''
        },
        success: (res) => {
          this.bloading = false
          this.message = ''
          this.fileList = []
          this.chatMessages = []
          this.page = 1
          this.getchatlist()
        },
        tip: () => {
          this.bloading = false
        },
      })
    }
  },
}
</script>
<style lang='less' scoped>
.indexbox {
  width: 100%;
  min-height: 100vh;
  .content_box {
    overflow: scroll;
    height: calc(100vh - 115px);
    position: relative;
    padding: 10px 15px;
    .listcontent {
      width: 100%;
      height: auto;
      .item_loading {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 0 10px;
      }
      .item {
        width: 100%;
        box-sizing: border-box;
        padding-top: 15px;
        .userbox {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          .user_img {
            width: 40px;
            height: 40px;
            border-radius: 20px;
          }
          .contentbox {
            max-width: 76%;
            padding: 4px 0 0 10px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            .msg_content {
              max-width: 100%;
              background: #fff;
              border-radius: 6px 6px 6px 6px;
              padding: 8px;
              color: #14223F;
              font-size: 14px;
              font-weight: 500;
              line-height: 1.4;
            }
            .img_content {
              width: 100%;
              padding: 8px 0 0;
              display: flex;
              align-items: center;
              >img {
                max-width: 50px;
                max-height: 50px;
                margin: 0 10px 0 0;
                object-fit: contain;
              }
            }
            .time {
              margin: 10px 0;
              font-size: 12px;
            }
          }
        }
        .author_my {
          flex-direction: row-reverse;
          .contentbox {
            padding: 4px 10px 0 0;
            align-items: flex-end;
            .msg_content {
              background: #FCE0D0;
            }
            .img_content {
              justify-content: flex-end;
              img {
                margin: 0 0 0 10px;
              }
            }
          }
        }
      }
      .chat-notice {
        width: 100%;
        text-align: center;
        padding: 10px 0;
        font-size: 14px;
        span {
          color: #bfc3d3;
        }
      }
    }
  }
  /deep/.van-goods-action {
    display: flex;
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: #196244;
    .imgbox {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >img {
        width: 35px;
        height: 35px;
        object-fit: cover;
        margin: 5px;
      }
    }
    .footboxitem {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      .van-uploader__upload {
        margin: 0;
      }
      .van-button {
        border-radius: 10px;
      }
      .van-cell {
        flex: 1;
        margin: 0 10px;
        background: #F5F5F5;
        border-radius: 21px 21px 21px 21px;
        .van-field__control {
          color: #196244;
          font-weight: 550;
          font-size: 16px;
        }
      }
    }
  }
}
</style>