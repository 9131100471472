<template>
  <div class='indexbox'>
    <nav-bar title="我的社区" left-arrow />
    <div class="headerbox">
      <div class="topbox">
        <div>
          <p>{{ info.team_amount }}</p>
          <p>{{ $t('社区业绩') }}</p>
        </div>
        <img src="../../../assets/img/a.png" alt="">
      </div>
      <div class="numbox">
        <div>
          <p>{{ info.team_num }}</p>
          <p>{{ $t('社区人数') }}</p>
        </div>
        <div>
          <p>{{ info.recomd_num }}</p>
          <p>{{ $t('直推人数') }}</p>
        </div>
      </div>
    </div>
    <div class="bodybox">
      <div class="titboxs">{{ $t('社区会员列表') }}</div>
      <van-list v-model="loading" :finished="finished" :finished-text="`${$t('没有更多了')}`" @load="getcarList">
        <div class="item" v-for="val in list" :key="val.id">
          <p class="addres">{{ addredFun(val.account) }}</p>
          <p class="num" v-if="val.is_effect == 1">{{ $t('有效') }}</p>
          <p class="num" v-if="val.is_effect == 0">{{ $t('无效') }}</p>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      info: {},
      list: [],
      page: 1,
      loading: false,
      finished: false,
    }
  },
  created() {
    this.getdata()
  },
  methods: {
    getdata() {
      this.$post({
        url: "/app/member/teamInfo",
        success: (res) => {
          this.info = res.data
        },
        tip: () => { },
      })
    },
    getcarList() {
      var page = this.page++
      this.$post({
        url: "/app/member/teamList",
        data: {
          page,
        },
        success: (res) => {
          if (!res.data.data || res.data.data.length == 0) {
            this.finished = true
          } else {
            var list = res.data.data
            if (page == 1) {
              this.list = list
            } else {
              for (var i in list) {
                this.list.push(list[i])
              }
            }
          }
          this.loading = false
        },
        tip: () => {
          this.finished = true
        },
      })
    },
    addredFun(account) {
      if (account.length > 10) {
        return account.substr(0, 8) + '....' + account.substr(account.length - 8)
      } else {
        return account
      }
    }
  },
}
</script>

<style scoped lang='less'>
.indexbox {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 10px 15px;
  .headerbox {
    width: 100%;
    padding: 20px;
    background: url('../../../assets/img/z.png') no-repeat;
    background-size: 100% 100%;
    margin-bottom: 15px;
    .topbox {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      >div {
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px 0;
        color: #117948;
        font-size: 14px;
        p:nth-child(1) {
          font-size: 26px;
          font-weight: 550;
        }
      }
      img {
        width: 80px;
        height: 80px;
      }
    }
    .numbox {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      >div {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        color: #117948;
        font-size: 14px;
        p:nth-child(1) {
          font-size: 22px;
          font-weight: 550;
        }
      }
    }
  }
  .bodybox {
    width: 100%;
    padding: 15px 15px 5px;
    background: #117948;
    border-radius: 10px 10px 10px 10px;
    border: 1px solid #B3A7AE;
    .titboxs {
      width: 100%;
      color: #FCDABF;
      font-size: 16px;
      font-weight: 550;
    }
    .item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      .addres {
        width: 78%;
        color: #F6BE9D;
        font-size: 13px;
        font-weight: 550;
      }
      .num {
        width: 20%;
        text-align: right;
        color: #F6BE9D;
        font-size: 13px;
        font-weight: 550;
      }
    }
  }
}
</style>
