<template>
  <div class='indexbox'>
    <nav-bar title="提现记录" left-arrow />
    <div class="titbox">
      <van-list v-model="loading" :finished="finished" :finished-text="`${$t('没有更多了')}`" @load="onLoad" :offset="0">
        <div class="list" v-for="val in list" :key="val.id">
          <div class="dest">{{ val.address }}</div>
          <div class="add">{{ $t('数量') }}：{{ val.amount }}</div>
          <div class="add">{{ $t('实际到账') }}：{{ val.actual }}</div>
          <div class="time">{{ $t(['待审核', '已通过', '已驳回'][val.status]) }}</div>
          <div class="time">{{ val.create_time }}</div>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      page: 1,
      loading: false,
      finished: false,
    }
  },
  methods: {
    onLoad() {
      var page = this.page++
      this.$post({
        url: "/app/withdraw/withdrawList",
        data: {
          page: page,
        },
        success: (res) => {
          if (!res.data || res.data.data.length == 0) {
            this.finished = true
          } else {
            var list = res.data.data
            if (page == 1) {
              this.list = list
            } else {
              for (var i in list) {
                this.list.push(list[i])
              }
            }
          }
          this.loading = false
        },
        tip: () => {
          this.finished = true
        },
      })
    },
  },
}
</script>
<style lang='less' scoped>
.indexbox {
  width: 100%;
  min-height: 100vh;
  .titbox {
    width: 100%;
    padding: 10px 15px;
    .list {
      width: 100%;
      background: rgba(11, 11, 11, .2);
      border-radius: 10px;
      margin-bottom: 10px;
      padding: 10px;
      .dest {
        font-size: 15px;
        font-weight: 550;
        color: #F6BE9D;
      }
      .time {
        font-size: 14px;
        color: #F6BE9D;
      }
      .add {
        font-size: 14px;
        color: #FCDABF;
        font-weight: 550;
      }
    }
  }
}
</style>