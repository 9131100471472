module.exports = {
  "首页": "home page",
  "订单": "order",
  "个人": "personal",
  "DNA": "DNA",
  "权益专区": "Equity Zone",
  "精选好物": "Selected Good",
  "健康服务": "Health",
  "特惠专区": "Special offers",
  "公告": "Notice",
  "精选商品": "Selected Products",
  "立即购买": "Buy Now",
  "没有更多了": "No more",
  "待付款": "To be paid",
  "待确认": "To be confirmed",
  "待核销": "To be written off",
  "已完成": "Completed",
  "订单取消": "Order cancellation",
  "地址验证登录": "Address verification login",
  "收益钱包": "Revenue Wallet",
  "LP代币": "LP Tokens",
  "我的订单": "My Order",
  "我的社区": "My Community",
  "分享好友": "Share Friends",
  "分享": "Share",
  "语言切换": "Language switching",
  "客服中心": "Customer Service Center",
  "密码设置": "Password Settings",
  "商品详情": "Product Details",
  "赠送": "give",
  "数量": "quantity",
  "限时特惠": "Indulgence",
  "产品详情": "product details ",
  "立即购买": "Buy Now",
  "请先设置安全密碼": "Please set the transaction password first",
  "输入支付密码": "Enter payment password",
  "价值": "value",
  "提交": "Submit",
  "立即支付": "Immediate payment",
  "成功": "success",
  "失败": "fail",
  "消息列表": "Message List",
  "订单详情": "Order Details",
  "价格明细": "Price breakdown",
  "商品总价": "Total price of goods",
  "实际支付": "Actual payment",
  "订单信息": "Order Information",
  "订单号": "order number",
  "创建时间": "Creation time",
  "付款时间": "Payment time",
  "复制成功": "Copy succeeded",
  "复制失败": "copy failed",
  "支付成功": "Payment succeeded",
  "您已完成支付": "You have completed the payment",
  "知道了": "got it",
  "安全密碼": "Transaction password",
  "旧安全密碼": "Old transaction password",
  "请输入旧安全密碼": "Please enter the old transaction password",
  "重复密码": "Duplicate password",
  "请输入重复安全密碼": "Please enter a duplicate transaction password",
  "确定": "determine",
  "两次安全密碼输入不一致": "The password input for the two transactions is inconsistent",
  "请确认安全密碼": "Please confirm the transaction password",
  "客服留言": "Customer service message",
  "记录": "record",
  "请输入留言": "Please enter a message",
  "发送": "send out",
  "反馈记录": "Feedback record",
  "查看回复": "View replies",
  "反馈详情": "Feedback Details",
  "回复": "reply",
  "系统回复": "System response",
  "分享链接": "Share Link",
  "复制分享链接": "Copy Share Link",
  "复制成功": "Copy succeeded",
  "复制失败": "copy failed",
  "明细": "detailed",
  "社区业绩": "Performance",
  "社区人数": "community",
  "直推人数": "direct push",
  "社区会员列表": "Member List",
  "密码设置": "Password Settings",
  "修改安全密碼": "Modify transaction password",
  "钱包明细": "Wallet Details",
  "余额": "balance",
  "提现": "Withdrawal",
  "余额变动明细": "Balance change details",
  "提现数量": "Withdrawal quantity",
  "请输入提现数量": "Please enter the withdrawal quantity",
  "手续费": "Service Charge",
  "最低提现数量": "Number of withdrawals",
  "提现倍数": "Withdrawal multiple",
  "实际到账": "Actual receipt",
  "请输入安全密碼": "Please enter the transaction password",
  "提现记录": "Cash withdrawal records",
  "待审核": "To be reviewed",
  "已通过": "Passed",
  "已驳回": "Rejected",
  "钱包余额不足": "Insufficient wallet balance",
  "取消订单": "cancel",
  "系统未设置收款钱包地址": "The system has not set a collection wallet address",
  "请地址验证登录": "Please Address verification login",
  "钱包等待投放，暂时无法使用": "The wallet is waiting to be released and temporarily unavailable",
  "请切换到": "Please switch to Ethereum main network, Binance Smartchain",
  "空投": "Airdrop time",
  "赠送": "give time",
  "请复制您的链接在DAPP浏览器访问": "Please copy your link and access it in DAPP browser",
  "暂未绑定上级,请先绑定": "Currently not bound to superior, please bind first",
  "请输入上级": "Please enter the superior UID",
  "请输入": "Enter",
  "有效": "effective",
  "无效": "invalid",
}