module.exports = {
  "首页": "トップページ",
  "订单": "オーダー",
  "个人": "個人",
  "DNA": "DNA",
  "权益专区": "権益専区",
  "精选好物": "よいものを選りすぐる",
  "健康服务": "健康サービス",
  "特惠专区": "特恵コーナー",
  "公告": "公告",
  "精选商品": "商品を精選する",
  "立即购买": "今すぐ購入",
  "没有更多了": "これ以上ない",
  "待付款": "支払い保留中",
  "待确认": "確認待ち",
  "待核销": "消込保留中",
  "已完成": "完了",
  "订单取消": "注文取消",
  "地址验证登录": "アドレス認証ログイン",
  "收益钱包": "収益財布",
  "LP代币": "LP トークン",
  "我的订单": "私の注文",
  "我的社区": "マイコミュニティ",
  "分享好友": "友達を分かち合う",
  "分享": "分かち合う",
  "语言切换": "言語切り替え",
  "客服中心": "カスタマーセンター",
  "密码设置": "パスワード設定",
  "商品详情": "商品の詳細",
  "赠送": "プレゼント",
  "数量": "数量",
  "限时特惠": "時限特恵",
  "产品详情": "製品の詳細",
  "立即购买": "今すぐ購入",
  "请先设置安全密碼": "まず取引パスワードを設定してください",
  "输入支付密码": "支払パスワードの入力",
  "价值": "の価値判断を",
  "提交": "送信",
  "立即支付": "即時支払い",
  "成功": "成功",
  "失败": "に失敗",
  "消息列表": "メッセージ・リスト",
  "订单详情": "注文の詳細",
  "价格明细": "価格詳細",
  "商品总价": "商品の総額",
  "实际支付": "実際の支払い",
  "订单信息": "オーダー情報",
  "订单号": "注文番号",
  "创建时间": "作成時間",
  "付款时间": "支払時間",
  "复制成功": "コピー成功",
  "复制失败": "コピーに失敗しました",
  "支付成功": "支払いに成功しました",
  "您已完成支付": "支払いが完了しました",
  "知道了": "わかりました",
  "安全密碼": "トランザクションパスワード",
  "旧安全密碼": "旧トランザクションパスワード",
  "请输入旧安全密碼": "古いトランザクションパスワードを入力してください",
  "重复密码": "パスワードの繰り返し",
  "请输入重复安全密碼": "重複取引パスワードを入力してください",
  "确定": "を選択して、",
  "两次安全密碼输入不一致": "2回の取引パスワード入力が一致しない",
  "请确认安全密碼": "取引パスワードを確認してください",
  "客服留言": "カスタマーサービスの伝言",
  "记录": "レコード破り",
  "请输入留言": "メッセージを入力してください",
  "发送": "送信",
  "反馈记录": "フィードバックレコード",
  "查看回复": "返信の表示",
  "反馈详情": "フィードバック詳細",
  "回复": "返信",
  "系统回复": "システム応答",
  "分享链接": "共有リンク",
  "复制分享链接": "共有リンクのコピー",
  "复制成功": "コピー成功",
  "复制失败": "コピーに失敗しました",
  "明细": "詳細",
  "社区业绩": "業績",
  "社区人数": "コミュニティ",
  "直推人数": "直進",
  "社区会员列表": "コミュニティメンバーリスト",
  "密码设置": "パスワード設定",
  "修改安全密碼": "トランザクションパスワードの変更",
  "钱包明细": "ウォレットの詳細",
  "余额": "残高",
  "提现": "引き出し",
  "余额变动明细": "残高変動明細",
  "提现数量": "引き出し数量",
  "请输入提现数量": "引き出し数量を入力してください",
  "手续费": "手数料",
  "最低提现数量": "リフト数",
  "提现倍数": "げんきんばいすう",
  "实际到账": "実際の入金",
  "请输入安全密碼": "取引パスワードを入力してください",
  "提现记录": "キャッシュレコード",
  "待审核": "レビュー保留中",
  "已通过": "合格",
  "已驳回": "却下されました",
  "钱包余额不足": "財布の残高が不足している",
  "取消订单": "注文の取消",
  "系统未设置收款钱包地址": "システムは入金ウォレット住所を設定していません",
  "请地址验证登录": "アドレス認証ログイン",
  "钱包等待投放，暂时无法使用": "財布は投入待ちでしばらく使えません",
  "请切换到": "イーサ坊Ethereumマスターネットワーク、Binance Smartchainに切り替えてください",
  "空投": "くうげきじかん",
  "赠送": "贈呈日時",
  "请复制您的链接在DAPP浏览器访问": "リンクをコピーしてDAPPブラウザでアクセスしてください",
  "暂未绑定上级": "親をバインドしていません。先にバインドしてください",
  "请输入上级": "親を入力してください UID",
  "请输入": "入力してください",
  "有效": "有効",
  "无效": "無効です",
}