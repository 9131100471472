<template>
  <div class="container">
    <nav-bar title="安全密碼" left-arrow />
    <template v-if="info.id">
      <van-field v-if="!info.second_password" v-model="old_password" :label="$t('旧安全密碼')" :placeholder="$t('请输入旧安全密碼')"
        maxlength="6" clearable type="password" :border=false />
      <van-field v-model="pass" :label="$t('安全密碼')" :placeholder="$t('请输入安全密碼')" maxlength="6" clearable type="password"
        :border=false />
      <van-field v-model="passto" :label="$t('重复密码')" :placeholder="$t('请输入重复安全密碼')" maxlength="6" clearable
        type="password" :border=false />
      <div class="btnbox">
        <van-button block :loading="loading" type="primary" round
          color="linear-gradient(90deg, #FFE4CC 0%, #F9CEB0 48%, #F2B692 100%)" @click="editpass">{{ $t('确定')
          }}</van-button>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: {},
      old_password: "",
      pass: "",
      passto: "",
      loading: false,
    }
  },
  created() {
    this.getuser()
  },
  methods: {
    getuser() {
      this.$post({
        url: "/app/member/memberInfo",
        success: (res) => {
          this.info = res.data;
        },
        tip: () => { },
      });
    },
    editpass() {
      if (!this.info.second_password && !this.old_password) return this.$toast(this.$t('请输入旧安全密碼'))
      if (!this.pass) return this.$toast(this.$t('请输入安全密碼'))
      if (!this.passto) return this.$toast(this.$t('请确认安全密碼'))
      if (this.pass != this.passto) return this.$toast(this.$t('两次安全密碼输入不一致'))
      this.loading = true
      this.$post({
        url: "/app/member/editSecondPassword",
        data: {
          old_password: this.old_password,
          password: this.pass,
          re_password: this.passto,
        },
        success: () => {
          this.old_password = ''
          this.pass = ''
          this.passto = ''
          this.$toast({
            message: this.$t("成功"),
            onClose: () => {
              this.loading = false
              this.getuser()
            }
          })
        },
        tip: () => {
          this.loading = false
        },
      })
    },
  },
}
</script>

<style scoped lang="less">
.container {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 10px 15px;
  /deep/.van-cell {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    background: #117948;
    border-radius: 10px 10px 10px 10px;
    border: 1px solid #B3A7AE;
    display: flex;
    align-items: center;
    .van-cell__title,
    .van-field__control {
      color: #F6BE9D;
    }
  }
  .btnbox {
    width: 100%;
    margin-top: 100px;
  }
}
</style>
