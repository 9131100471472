<template>
  <div class='indexbox'>
    <nav-bar title="DNA" />
    <div class="header">
      <van-swipe class="myswipe" :autoplay="3000" indicator-color="#fff">
        <van-swipe-item v-for="val in banner" :key="val.id">
          <img v-lazy="val.image_path" alt>
        </van-swipe-item>
      </van-swipe>
      <!-- <van-grid column-num="4" :border="false">
        <van-grid-item>
          <template>
            <img src="../../assets/img/j.png" alt="">
            <p>{{ $t('权益专区') }}</p>
          </template>
        </van-grid-item>
        <van-grid-item>
          <template>
            <img src="../../assets/img/k.png" alt="">
            <p>{{ $t('精选好物') }}</p>
          </template>
        </van-grid-item>
        <van-grid-item>
          <template>
            <img src="../../assets/img/l.png" alt="">
            <p>{{ $t('健康服务') }}</p>
          </template>
        </van-grid-item>
        <van-grid-item>
          <template>
            <img src="../../assets/img/m.png" alt="">
            <p>{{ $t('特惠专区') }}</p>
          </template>
        </van-grid-item>
      </van-grid> -->
    </div>
    <div class="bodybox">
      <div class="newbox">
        <p @click="$router.push('newlist')">{{ $t('公告') }}</p>
        <van-swipe class="mynews" :autoplay="3000" vertical :show-indicators="false">
          <van-swipe-item v-for="val in newlist" :key="val.id"
            @click="$router.push({ name: 'newdel', query: { id: val.id } })">
            <p class="one">{{ val.title }}</p>
            <van-icon name="arrow" color="#fff" size="14" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="listbox">
        <p class="tits">{{ $t('精选商品') }}</p>
        <div class="item" v-for="val in list" :key="val.id" @click="router(`details?id=${val.id}`)">
          <img v-lazy="val.image" alt="">
          <div class="goods">
            <p class="name one">{{ val.name }}</p>
            <p class="msg">{{ val.service }}</p>
            <div class="btnbox">
              <div class="bts">
                <div class="one">
                  <p class="one">{{ val.usdt_price }}<span>U</span></p>
                </div>
                <div>
                  <p>{{ $t('立即购买') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      banner: [],
      newlist: [],
      list: [],
    }
  },
  created() {
    this.get_banner()
  },
  methods: {
    get_banner() {
      this.$post({
        url: "/app/slider/sliderList",
        success: (res) => {
          this.banner = res.data;
        },
        tip: () => { },
      });
      this.$post({
        url: "/app/notice/pageList",
        data: {
          page: 1,
        },
        success: (res) => {
          this.newlist = res.data.data
          this.getlist()
        },
        tip: () => { },
      })

    },
    getlist() {
      this.$post({
        url: "/app/goods/pageList",
        success: (res) => {
          this.list = res.data.data
        },
        tip: () => {
        },
      })
    },
  },
}
</script>
<style scoped lang="less">
.indexbox {
  width: 100%;
  .header {
    width: 100%;
    padding: 0 15px 15px;
    .myswipe {
      width: 100%;
      border-radius: 2vw;
      background: rgba(255, 255, 255, 0.39);
      /deep/.van-swipe-item {
        width: 100%;
        height: 100%;
        >img {
          width: 100%;
          height: 150px;
          object-fit: cover;
          border-radius: 10px;
        }
      }
    }
    /deep/.van-grid {
      margin-top: 30px;
      .van-grid-item__content {
        background: none !important;
        padding: 10px 0;
        img {
          width: 46px;
          height: 46px;
        }
        p {
          color: #fff;
          font-size: 12px;
          font-weight: 500;
          margin-top: 5px;
        }
      }
    }
  }
  .bodybox {
    width: 100%;
    padding: 0 15px 80px;
    .newbox {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      background: rgba(11, 11, 11, .2);
      border-radius: 10px;
      border-radius: 10px;
      padding: 3px 16px;
      >p {
        color: #fff;
        font-size: 15px;
        font-weight: 550;
        position: relative;
        padding-right: 10px;
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          right: 0;
          width: 2px;
          height: 10px;
          background: #fff;
          transform: translateY(-50%);
        }
      }
      .mynews {
        flex: 1;
        height: 10vw;
        padding-left: 10px;
        .van-swipe-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100% !important;
          >p {
            width: 94%;
            color: #fff;
            font-weight: 550;
            font-size: 15px;
            line-height: 10vw;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
    .listbox {
      width: 100%;
      margin-top: 15px;
      padding: 10px;
      border-radius: 10px 10px 10px 10px;
      border: 2px solid #B3A7AE;
      .tits {
        width: 100%;
        color: #fff;
        font-weight: 550;
        font-size: 16px;
      }
      .item {
        width: 100%;
        margin-top: 15px;
        background: rgba(28, 114, 79, .9);
        border-radius: 10px 10px 10px 10px;
        display: flex;
        justify-content: space-between;
        padding: 10px;
        >img {
          width: 100px;
          height: 100px;
          border-radius: 10px;
        }
        .goods {
          width: 64%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          padding: 4px 0;
          .name {
            width: 100%;
            color: #fff;
            font-size: 15px;
            font-weight: 550;
          }
          .msg {
            width: 100%;
            color: #fff;
            font-size: 12px;
          }
          .btnbox {
            width: 100%;
            display: flex;
            margin-top: 8px;
            .bts {
              max-width: 100%;
              display: flex;
              justify-content: flex-start;
              border-radius: 10px;
              overflow: hidden;
              >div {
                transform: skewX(-15deg);
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 5px 15px;
                &:nth-child(1) {
                  max-width: 63%;
                  margin-left: -5px;
                  background: #08462D;
                  color: #fff;
                  font-size: 15px;
                  font-weight: 550;
                  p {
                    width: 100%;
                    span {
                      font-size: 12px;
                    }
                  }
                }
                &:nth-child(2) {
                  background: linear-gradient(93deg, #FFE4CC 0%, #F2B692 100%);
                  color: #000;
                  font-size: 12px;
                  border-radius: 0 0 10px 0;
                  font-weight: 550;
                  margin-right: -5px;
                  padding: 5px 12px 5px 10px;
                }
                p {
                  transform: skewX(15deg);
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
